.hero {
  height: 100vh;
  width: 100%;
  overflow: hidden;

  &-img {
    background-size: cover;
    background-image: url("https://res.cloudinary.com/dmhwdtooa/image/upload/v1634716915/photos/000052_pt3sla_rw6uor.jpg");
    background-position:center;

    width: 100%;
    height: 100%;

    -webkit-animation: heroAnim 15s ease 1s forwards;
    animation: heroAnim 15s ease 1s forwards;
  }

}
