.nav-slide {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  // background-image: linear-gradient(to bottom, #0f0f0f, #0f0f0fe5, #191a1d00);
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  transition: all .4s ease-out;
  transform: translateY(-100%);
  opacity: 0;


  .page-links {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;

    hr {
      width: 50%;
      margin: 1rem;
    }
  }

  .link-page {
    width: 100%;
    margin: 0 1rem;
    font-family: 'Bebas Neue', cursive;
    font-size: 4rem;
    font-weight: 400;
    font-style: normal;
    opacity: 0;
    cursor: pointer;

    color: black;

    &:focus {
      outline: none;
    }


    &-open {

      -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
      clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
      transform: translateY(-50px);
      opacity: 0;
      animation-name: titleAnimation;
      animation-timing-function: ease;
      animation-delay: .5s;
      animation-duration: .5s;
      animation-fill-mode: forwards;

      @keyframes titleAnimation {
        0% {
          transform: translateY(-50px);
          opacity: 0;
          -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
          clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
        }

        100% {
          transform: translateY(0);
          opacity: 1;
          -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
          clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
        }

      }
    }
  }

  .social-icon {
    width: 2rem;
    height: 2rem;
    background-color: white;
  }
}
